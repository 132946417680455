import { createContext } from "react";

//card component
const defaultProject = [
  {
    id: 1,
    github: "www.test.com",
    title: "Title Here 1",
    subtitle: "Sub-title 1",
    text: "something about the project",
    stacks: [{ id: "s1", stack1: "CSS", stack2: "HTML", stack3: "React" }],
  },
  {
    id: 2,
    github: "www.test.com",
    title: "Title Here 2",
    subtitle: "Sub-title 2",
    text: "something about the project",
    stacks: [{ id: "s1", stack1: "CSS", stack2: "HTML", stack3: "React" }],
  },
  {
    id: 3,
    github: "www.test.com",
    title: "Title Here 3",
    subtitle: "Sub-title 3",
    text: "something about the project",
    stacks: [{ id: "s1", stack1: "CSS", stack2: "HTML", stack3: "React" }],
  },
];
//This creates a projectContext with a default value of codeProject
export const ProjectContext = createContext(defaultProject);
