import Nav from "../components/Nav";

const About = () => {
  return (
    <div className="container-fluid" id="container-padding">
      <Nav></Nav>
      <div className="container-xxl outer-wrapper">
        <div className="inner-wrapper d-flex justify-content-start" id="about">
          <div className="left">
            <h1>About Me.</h1>
            <p>
              Hi, I'm Ethel. I took and completed a diploma of graphic design
              course at CATC Design school. It is where I found my passion and
              interest in building things for the web and pursued Information
              Technology major in software development at Griffith University.
            </p>
            <p>
              Today, I have the privilege of working for a start up company
              building different platforms using various and modern technology.
              Here are some of the technologies I am currently working on...
              <ul id="tech-stacks" className="py-2">
                <li>JavaScript (ES6)</li>
                <li>React</li>
                <li>TypeScript</li>
                <li>Node JS</li>
                <li>AWS</li>
              </ul>
            </p>
          </div>
          <div className="right">
            <img
              src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/right-image.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
