import { useContext } from "react";
import { ProjectContext } from "../context/ProjectContext";

const Card = () => {
  const project = useContext(ProjectContext);
  return (
    <ul className="map-card d-flex justify-content-center">
      {project.map((project) => (
        <li key={project.id} className="card-list" id="card-list">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <a href={project.github} alt="" target="_blank" rel="noreferrer">
                <img
                  src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/icon-folder.png"
                  alt="check code"
                  className="icon-folder"
                />
              </a>
              <a href={project.github} alt="" target="_blank" rel="noreferrer">
                <img
                  src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/icon-github.png"
                  alt=""
                  id="icon-github"
                />
              </a>
            </div>

            <div className="card-body">
              <h3>{project.title}</h3>

              <p className="mute">{project.subtitle}</p>
              <p>{project.text}</p>
            </div>

            <div className="card-footer">
              <ul id="work-card-footer">
                {project.stacks.map((stack) => (
                  <li key={stack.id} className="d-flex justify-content-between">
                    <p>{stack.stack1}</p>
                    <p>{stack.stack2}</p>
                    <p>{stack.stack3}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Card;
