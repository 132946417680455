import Nav from "../components/Nav";
import Card from "../components/Card";
import DesignList from "../components/CardDesign";

const Work = () => {
  return (
    <div className="container-fluid" id="overflow-scroll">
      <Nav />
      <div className="container-xxl outer-wrapper" id="work">
        <h1 className="top-title">Some things I've built.</h1>
        <section className="row box-1">
          <img
            src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/work_1.png"
            alt=""
            id="cinefly-site"
          />
          <div className="sub-box sub-box-1 col-7">
            <p id="feat-1" className="d-flex justify-content-end mono-span">
              Featured Work
            </p>
            <h3 className="d-flex justify-content-end">Cinefly Website</h3>

            <p className="p-4 bg-dark-lighter">
              Cinefly is a media-tech company specialising in user-generated
              video content (UGC). My task is to design, build, and maintain the
              website and ensure that the site is responsive all across devices.
              <br />
              <br />
              <div className="url-feature">
                <sub>visit the site</sub>&nbsp;&nbsp;
                <a
                  href="https://neo.cinefly.io/"
                  alt=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/icon-link.png"
                    alt=""
                    className="icon-link"
                  />
                </a>
              </div>
            </p>
            <ul className="d-flex justify-content-between">
              <li>HTML5</li>
              <li>CSS/SASS</li>
              <li>JavaScript</li>
              <li>Serverless</li>
            </ul>
          </div>
        </section>
        <section className="row box-2 my-3">
          <img
            src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/work_2.png"
            alt=""
            id="cinefly-portal"
          />
          <div className="sub-box sub-box-2 col-7">
            <p id="feat-2" className="mono-span">
              Featured Work
            </p>
            <h3 className="d-flex">Cinefly Portal</h3>
            <p className="p-4 bg-dark-lighter">
              The cinefly portal enables the client to login and access their
              account. My task was to design the UI/UX and translate them into
              code as a front-end developer. I am also responsible for its
              maintenance and any additional new features that are required.
              <br />
              <br />
              <div>
                <sub>visit the site</sub>&nbsp;&nbsp;
                <a
                  href="https://neo.portal.cinefly.io/#/"
                  alt=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/icon-link.png"
                    alt=""
                    className="icon-link"
                  />
                </a>
              </div>
            </p>
            <ul className="d-flex justify-content-between">
              <li>CSS/SASS</li>
              <li>React</li>
              <li>TypeScript</li>
              <li>Serverless</li>
            </ul>
          </div>
        </section>
        <section className="other">
          <h1 className="d-flex justify-content-center" id="other">
            Other Projects.
          </h1>
          <div className="card-wrapper">
            <Card />
          </div>
        </section>

        <section id="design">
          <h1 className="d-flex justify-content-center">
            Things I've designed.
          </h1>
          <div className="card-wrapper">
            <DesignList />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Work;
