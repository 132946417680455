import Nav from "../components/Nav";
import Typewriter from "typewriter-effect";

const Home = () => {
  return (
    <div className="container-fluid">
      <Nav />
      <div className="container-xxl outer-wrapper">
        <div className="inner-wrapper" id="home">
          <p className="mono-span">
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString("Hi, my name is ")
                  .pauseFor(1000)
                  .deleteAll()
                  .typeString("Hi, my name is")
                  .start();
              }}
            />
          </p>
          <h1>
            <span>Ethel Beckett.</span><br />I build and design things for the
            web
          </h1>
          <p className="text-p col-5">
            I'm a software developer specializing in web development and design.
            Currently, I work full time as a frontend developer and a UI/UX
            designer at
            <span> Cinefly</span>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
