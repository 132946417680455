const design = [
  {
    id: 1,
    thumbnail: "twoHeartbeats-thumbnail.jpg",
    full: "twoHeartbeats-full.jpg",
    title: "Two Heartbeats",
    subtitle: "book cover",
    client: "Rhonda",
  },
  {
    id: 2,
    thumbnail: "timeWilltell-thumbnail.jpg",
    full: "timeWilltell-full.jpg",
    title: "Time Will Tell (book 2)",
    subtitle: "Book cover",
    client: "Rhonda",
  },
  {
    id: 3,
    thumbnail: "SilkwormSecrets_thumbnail.jpg",
    full: "silkwormSecrets-full.jpg",
    title: "Silkworm Secrets",
    subtitle: "Book cover",
    client: "Rhonda",
  },
  {
    id: 4,
    thumbnail: "trilogy-book1-thumbnail.jpg",
    full: "trilogy-book1-full.jpg",
    title: "Elizabeth's Star (book 1)",
    subtitle: "Book cover",
    client: "Rhonda",
  },
  {
    id: 5,
    thumbnail: "trilogy-book2-thumbnail.jpg",
    full: "trilogy-book2-full.jpg",
    title: "Until We Meet (book 2)",
    subtitle: "Book cover",
    client: "Rhonda",
  },
  {
    id: 6,
    thumbnail: "trilogy-book3-thumbnail.jpg",
    full: "trilogy-book3-full.jpg",
    title: "We'll Meet Again (book 3)",
    subtitle: "Book cover",
    client: "Rhonda",
  },
  {
    id: 7,
    thumbnail: "julie-thumbnail.jpg",
    full: "Julie-Poster.jpg",
    title: "Women's Boxing",
    subtitle: "Poster",
    client: "Julie",
  },
  {
    id: 8,
    thumbnail: "ArtsTheatre-thumbnail.jpg",
    full: "ArtsTheatre-full.jpg",
    title: "When the rain stops falling",
    subtitle: "Flyer",
    client: "Arts from the margins",
  },
  {
    id: 9,
    thumbnail: "horizon-thumbnail.jpg",
    full: "horizon-full.jpg",
    title: "Horizon Concrete",
    subtitle: "Logo",
    client: "Horizon Concrete",
  },
  {
    id: 10,
    thumbnail: "newsletter02-thumbnail.jpg",
    full: "newsletter02-full.jpg",
    title: "Amazon Jungle",
    subtitle: "RTAM Newsletter",
    client: "BlueInk Media",
  },
  {
    id: 11,
    thumbnail: "newsletter01-thumbnail.jpg",
    full: "newsletter01-full.jpg",
    title: "Committee Reports",
    subtitle: "RTAM Newsletter",
    client: "BlueInk Media",
  },
];

const DesignList = () => {
  const http = "https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/";

  return (
    <ul className="map-design d-flex justify-content-center">
      {design.map((design) => (
        <li className="card-list">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <img
                src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/icon-design-folder.png"
                alt=""
                className="icon-design-folder "
              />
              <a
                href={http + design.full}
                alt=""
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/icon-link.png"
                  alt=""
                  className="icon-link"
                />
              </a>
            </div>
            <div className="card-body">
              <a
                href={http + design.full}
                alt=""
                target="_blank"
                rel="noreferrer"
              >
                <img src={http + design.thumbnail} alt="" />

                <div className="overlay"></div>
              </a>
            </div>
            <div className="card-footer">
              <p>
                {design.title} <br />
                <span className="mute">{design.subtitle}</span>
                <br />
                <sub>commissioned by: {design.client}</sub>
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default DesignList;
