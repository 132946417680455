import { Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import About from "./pages/About.js";
import Home from "./pages/Home.js";
import Experience from "./pages/Experience.js";
import Work from "./pages/Work.js";
import Contact from "./pages/Contact.js";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-253050939-1";

ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    const loadComponent = async () => {
      // show animation
      setIsLoading(true);

      // simulate component loading
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // hide animation
      setIsLoading(false);
    };

    loadComponent();
  }, []);

  if (isLoading) {
    return (
      <div className="loading-wrapper">
        <img
          src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/logo.png"
          alt="ethel beckett logo"
          className="rotation"
        ></img>
      </div>
    );
  }

  return (
    <>
      <ul className="footer-socials">
        <a
          href="https://www.linkedin.com/in/ethel-beckett-1846b6a0/"
          alt="linkedin"
          rel="noreferrer"
          target={"_blank"}
        >
          <li>
            <img
              src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/social-linkedin.png"
              alt=""
            ></img>
          </li>
        </a>
        <a
          href="https://github.com/ejb3ck3tt"
          alt="linkedin"
          rel="noreferrer"
          target={"_blank"}
        >
          <li>
            <img
              src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/social-github.png"
              alt=""
            ></img>
          </li>
        </a>
        <a
          href="https://www.instagram.com/ej.beckett"
          alt="linkedin"
          rel="noreferrer"
          target={"_blank"}
        >
          <li>
            <img
              src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/social-instagram.png"
              alt=""
            ></img>
          </li>
        </a>
      </ul>
      <ul className="social-email text-light">
        <li>
          <p>ethelbeckett@gmail.com</p>
        </li>
      </ul>
      <div className="footer-power">
        Made with <span> ♥ </span> and <span> ☕ </span>by Ethel © 2023
      </div>

      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/work" element={<Work />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
