import Nav from "../components/Nav";
const Contact = () => {
  return (
    <div className="container-fluid">
      <Nav />
      <div className="container-xxl outer-wrapper">
        <div className="inner-wrapper" id="contact">
          <ul className="d-flex ">
            <a
              href="https://www.linkedin.com/in/ethel-beckett-1846b6a0/"
              alt="linkedin"
              rel="noreferrer"
              target={"_blank"}
            >
              <li>
                <img
                  src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/social-linkedin.png"
                  alt=""
                ></img>
              </li>
            </a>
            <a
              href="https://github.com/ejb3ck3tt"
              alt="linkedin"
              rel="noreferrer"
              target={"_blank"}
            >
              <li>
                <img
                  src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/social-github.png"
                  alt=""
                ></img>
              </li>
            </a>
            <a
              href="https://www.instagram.com/ej.beckett"
              alt="linkedin"
              rel="noreferrer"
              target={"_blank"}
            >
              <li>
                <img
                  src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/social-instagram.png"
                  alt=""
                ></img>
              </li>
            </a>
          </ul>
          <h2>Get In Touch.</h2>
          <p>
            I'm currently not looking for new opportunities but if you have any
            questions or just want to say hi, please feel free to drop me a
            message and I will get back to you as soon as I can.
          </p>
          <br />
          <a
            class="email-link"
            href="mailto:ethelbeckett@gmail.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            <button className="btn btn-email">Say Hi</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
