import React, { useRef } from "react";
import Nav from "../components/Nav";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBScrollspy,
  MDBScrollspyLink,
} from "mdb-react-ui-kit";
const Experience = () => {
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const containerRef = useRef(null);

  return (
    <>
      <div className="container-fluid">
        <Nav />
        <div className="container-xxl inner-wrapper" id="experience">
          <MDBContainer>
            <h1 className="mdbContainer">Where I've Worked.</h1>
            <MDBRow>
              <MDBCol md="3" className="mono-span py-2">
                <MDBScrollspy container={containerRef}>
                  <MDBScrollspyLink targetRef={section1}>
                    Cinefly
                  </MDBScrollspyLink>
                  <MDBScrollspyLink targetRef={section2}>
                    Cisco
                  </MDBScrollspyLink>
                  <MDBScrollspyLink targetRef={section3}>
                    McArthur
                  </MDBScrollspyLink>
                  <MDBScrollspyLink targetRef={section4}>
                    Self-employed
                  </MDBScrollspyLink>
                </MDBScrollspy>
              </MDBCol>
              <MDBCol md="8">
                <div ref={containerRef} className="scrollspy">
                  <section ref={section1} id="section-1">
                    <h3>
                      Software Developer @<span>Cinefly</span>
                      <br />
                      <sub className="mono-span">Mar 2021 - Present</sub>
                    </h3>
                    <ul id="tech-work" className="work-experience-list">
                      <li>
                        Design and implementation of continuous integration and
                        deployment pipelines
                      </li>
                      <li>
                        Extending Node.js / TypeScript / Express API Services
                        across platforms and integrate frontend with the
                        constructed API
                      </li>
                      <li>
                        Test, prepare, implement and release new staging and
                        production for all across platform when there is a new
                        patches updates, fixes and new platform versions.
                      </li>
                      <li>
                        Design using wireframes, static and interactive
                        prototyping for portal web app, mobile and website using
                        Adobe XD and Illustrator.
                      </li>
                      <li>
                        Develop and lead interns for onboarding processes,
                        resources, and documentation
                      </li>
                    </ul>
                    <br />
                  </section>

                  <section ref={section2} id="section-2">
                    <h3>
                      Mentee @<span>Cisco</span>
                      <br />
                      <sub className="mono-span">Aug 2020 - Oct 2020</sub>
                    </h3>
                    <p>
                      Cisco MentorMe is a mentoring program for any
                      female-identifying University students who have a keen
                      passion for technology.
                    </p>
                    <ul id="section-3-work">
                      <li>
                        Learnt about different job roles in Cisco and in
                        Technology Industry
                      </li>
                      <li>Developed communication and leadership skills</li>
                      <li>Understanding strengths and weaknesses</li>
                      <li>
                        Meet and hear inspiring leaders about their career
                        journeys
                      </li>
                    </ul>
                    <br />
                    <br />
                    <br />
                  </section>

                  <section ref={section3} id="section-3">
                    <h3>
                      Data Maintenance Officer @<span>McArthur</span>
                      <br />
                      <sub className="mono-span">
                        Nov 2019 - Dec 2019 &amp; Mar 2020 - Apr 2020
                      </sub>
                    </h3>
                    <ul id="section-3-work">
                      <li>
                        Responsible for keeping and maintaining records
                        up-to-date
                      </li>
                      <li>
                        Follow up requirements with the reserves when it is
                        required urgently
                      </li>
                      <li>
                        Ensure new reserves are deployed on time and in
                        appropriate areas
                      </li>
                      <li>
                        Ensure new reserves have completed necessary training
                        before deployment
                      </li>
                      <li>Provide and configure access for new reserves</li>
                      <li>Data entry, generate and update training reports</li>
                    </ul>
                    <br />
                    <br />
                    <br />
                  </section>

                  <section ref={section4} id="section-4">
                    <h3>
                      Graphics and Digital Designer @<span>Self-employed</span>
                      <br />
                      <sub className="mono-span">
                        July 2018 - March 2021 &amp; Occasionally accepts
                        freelancing works
                      </sub>
                    </h3>
                    <p>
                      Creating visual brands that help people and businesses get
                      their message across in ways that are aesthetically
                      pleasing, engaging, and effective. Here are the list of
                      things I usually design:
                    </p>
                    <ul id="section-4-work">
                      <li>Book cover</li>
                      <li>Logo</li>
                      <li>Web Design</li>
                      <li>Posters</li>
                      <li>Flyers</li>
                      <li>Newsletters and Magazine</li>
                      <li>Any marketing materials and more...</li>
                    </ul>
                  </section>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
    </>
  );
};

export default Experience;
