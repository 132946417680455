import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./scss/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import { ProjectContext } from "./context/ProjectContext";

import ReactGA from "react-ga";

const TRACKING_ID = "UA-253050939-1";

ReactGA.initialize(TRACKING_ID);

const project = [
  {
    id: 1,
    github: "https://github.com/ejb3ck3tt/Restaurant_App",
    title: "Restaurant Review",
    subtitle: "Web App",
    text: "A simple restaurant review app with CRUD methods. It includes user login but without the authentication.",
    stacks: [{ id: "s1", stack1: "MERN Stack", stack2: "Bootstrap" }],
  },
  {
    id: 2,
    github: "https://github.com/ejb3ck3tt/Project_GameDev_Pacman",
    title: "PacMan",
    subtitle: "Game Development",
    text: "Recreated pacman from the ground up with the guidelines of the software engineering principles and patterns",
    stacks: [{ id: "s2", stack1: "HTML", stack2: "p5.js", stack3: "express" }],
  },
  {
    id: 3,
    github: "https://github.com/ejb3ck3tt/Project_ChatApp_AngularAndSocket",
    title: "Real-time Web Chat",
    subtitle: "Web App",
    text: "A real-time web chat application that contains users and admins with groups and channels.",
    stacks: [
      {
        id: "s3",
        stack1: "Angular",
        stack2: "NodeJS",
        stack3: "Socket.io",
      },
    ],
  },
];
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ProjectContext.Provider value={project}>
        <App />
      </ProjectContext.Provider>
    </BrowserRouter>
  </React.StrictMode>
);
