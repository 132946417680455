import { Link } from "react-router-dom";
import { useState } from "react";

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="container-nav primary" id="navbar">
      <a href="/">
        <img
          src="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/logo.png"
          alt="ethel beckett logo"
        ></img>
      </a>
      <ul className="nav justify-content-end">
        <li className="burger-menu">
          <button
            onClick={handleToggle}
            className={`burger-menu-btn ${isOpen === true ? "btn-active" : ""}`}
          >
            {isOpen ? (
              <div className="burger-wrapper">
                <li className="active-btn">
                  <button id="pos-absolute">x</button>
                </li>
                <li className="burger-item mt-2">
                  <Link to={"/about"} className="nav-link">
                    About
                  </Link>
                </li>
                <li className="burger-item">
                  <Link to={"/experience"} className="nav-link">
                    Experience
                  </Link>
                </li>
                <li className="burger-item">
                  <Link to={"/work"} className="nav-link">
                    Work
                  </Link>
                </li>
                <li className="burger-item">
                  <Link to={"/contact"} className="nav-link">
                    Contact
                  </Link>
                </li>
                <li className="burger-item" id="border-1">
                  <a
                    className="nav-link btn-outline"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/EthelBeckett-resume.pdf"
                    download
                  >
                    Resume
                  </a>
                </li>
              </div>
            ) : (
              <div>
                <ul id="burger-bars">
                  <li className="burger-bars-item"></li>
                  <li className="burger-bars-item" id="bar-2"></li>
                </ul>
              </div>
            )}
          </button>
        </li>

        <li className="nav-item margin-space">
          <Link to={"/about"} className="nav-link">
            About
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/experience"} className="nav-link">
            Experience
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/work"} className="nav-link">
            Work
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/contact"} className="nav-link">
            Contact
          </Link>
        </li>
        <li className="nav-item" id="border-1">
          <a
            className="nav-link btn-outline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/EthelBeckett-resume.pdf"
            download
          >
            Resume
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Nav;
